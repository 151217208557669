import React from "react";

const Callout = ({className = "", heading, children, type = "warning"}) => (
    <div className={`${className} alert alert-${type} border-${type} alert-callout alert-md-aligned-callout`}>
        {heading &&
        <h6 className="text-dark">{heading}</h6>
        }
        <div className="font-weight-light">
            {children}
        </div>
    </div>
);

const AskHowToInfoAlert = () => (
    <Callout className="mt-5 mb-0 mb-md-3" heading="Need Help?" type="primary">
        Send an e-mail to
        {' '}<a className="alert-link" href="mailto:support@sqlapi.com">support@sqlapi.com</a>{' '}
        if you want to ask code-level questions, if you’re stuck with a specific error or
        need advise on the library best practices.
    </Callout>
);

export {
    Callout,
    AskHowToInfoAlert
};
